export default {
    "API_KEY": "8O7cgGKyQ15Q8LPkTn2NCarEKLYdBSey7bPk6PZJ",
    "API_URL": "https://videoeditor-api-admin.asqq.io",
    "COGNITO_APP_CLIENT_ID": "11duvankk64ef22vq2ft96eal1",
    "COGNITO_DOMAIN": "videoeditor-api-admin-prod.auth.us-east-2.amazoncognito.com",
    "COGNITO_REDIRECT_SIGN_IN": "https://videoeditor-admin.asqq.io/",
    "COGNITO_REDIRECT_SIGN_OUT": "https://videoeditor-admin.asqq.io/",
    "COGNITO_USER_POOL_ID": "us-east-2_9iAuga7e8",
    "REGION": "us-east-2",
    "RELEASE_DATE": "2020-10-08 17:06:47.691989"
};